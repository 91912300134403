import config from '../config'
import fetch from '../http'
import { message } from 'antd'
import wx from "weixin-js-sdk"
import Cookies from 'js-cookie'
import md5 from './md5.js'

//获取行业id代表行业
export function getTradeById(id) {
	switch (id) {
		case 1: return '法律';
		case 2: return '专利';
		case 3: return 'google学术';
		case 4: return '百度学术';
		case 5: return '知网';
		case 6: return '万方';
		default: return ''
	}
}

// 检查环境
export function checkEnv(type) {
	var ua = navigator.userAgent.toLowerCase();
	switch (type) {
		case 'android':
			return /android/i.test(ua)
		case 'ios':
			return /(iPhone|iPad|iPod|iOS)/i.test(ua);
		case 'qq':
			return /mqqbrowser/i.test(ua)
		case 'weixin':
			return /micromessenger/i.test(ua)
		case 'weibo':
			return /weibo/i.test(ua)
			
		case 'ie':
			return /Trident/i.test(ua)||/MSIE/i.test(ua)	
		case 'chrome':
			return /chrome\/[\d.]+/ig.test(ua)
		case 'safari':
			return /version\/[\d.]+/ig.test(ua)
		case 'firefox':
			return /firefox\/[\d.]+/ig.test(ua)
		default:
	}
}

// 跳转登录
export function toLogin() {
	if(window.$isH5&&!window.isMini){
        let domains = window.location.host.match(/.fanyigou.[\w]+$/)
        let domain = domains?domains[0]:null
        Cookies.set('deviceId',Cookies.get('DeviceId')||'',{ domain:domain, expires: 7, path: '/' });
		window.location.href = config.H5_HOST+'/login?from=' + encodeURIComponent(window.location.href);
	}else if(window.isMini){
		toMiniProgram();

		function toMiniProgram(){
			let tologin = Cookies.get('tologin')
			if(tologin!==1){
				message.destroy();
				message.info('请先登录',1)
				Cookies.set('tologin',1);
				return setTimeout(function(){
					wx.miniProgram.navigateTo({
						url: '/pages/authorize/authorize',
					})
				},1000)
			}else{
				Cookies.set('tologin',0);
				setTimeout(function(){
					toMiniProgram();
				},100)
			}
		}
	}else{
		if (window.location.pathname === '') {
			window.location.href = config.routeHost + '/login.html?next=' + window.location.origin;
		} else {
			window.location.href = config.routeHost + '/login.html?next=' + encodeURIComponent(window.location.href);
		}
	}
}

// 获取DeviceId
export function getGuid(){
	return Cookies.get('DeviceId')||'';
	// if(!deviceId){
    //     deviceId = window.$fingerprint;
    //     if(deviceId){
    //         let domains = window.location.host.match(/.fanyigou.[\w]+$/)
    //         let domain = domains?domains[0]:null
    //         Cookies.set('DeviceId',deviceId,{ domain:domain, expires: 7, path: '/' });
    //     }
    // }
    // return deviceId
}

// 获取设备canvasId
export function createDeviceId(){
    function bin2hex(s) {
        var i, l, o = '',
          n;
      
        s += '';
      
        for (i = 0, l = s.length; i < l; i++) {
          n = s.charCodeAt(i)
            .toString(16);
          o += n.length < 2 ? '0' + n : n;
        }
      
        return o;
    }
    function getUUID(domain) {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        var txt = domain;
        ctx.textBaseline = "top";
        ctx.font = "14px 'Arial'";
        ctx.textBaseline = "tencent";
        ctx.fillStyle = "#f60";
        ctx.fillRect(125,1,62,20);
        ctx.fillStyle = "#069";
        ctx.fillText(txt, 2, 15);
        ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
        ctx.fillText(txt, 4, 17);
    
        var b64 = canvas.toDataURL().replace("data:image/png;base64,","");
        var bin = atob(b64);
        var crc = bin2hex(bin.slice(-16,-12));
        return crc;
    }
    
    return getUUID("http://zhishi.fanyigou.com")
}

// 统计页面跳转
export function visitHistory(target) {
	let fingerprint = new window.Fingerprint2();
	fingerprint.get((result) => {
		let params = {
			deviceId: getGuid(),
			browserFinger: result,
			target: target
		};
		fetch.visitHistory(params, res => { });
	})
}

// 获取翻译页面原路径与翻译路径
// export function getTranslateWebHref(type) {
// 	switch (type) {
// 		case 'springer': return {
// 			origin: 'https://rd.springer.com',
// 			trans: 'http://47.244.129.144/web/trans/springer.html',
// 			orititle: 'Home - Springer',
// 			tratitle: '主页 - 施普林格'
// 		}
// 		case 'nature': return {
// 			origin: 'https://www.nature.com',
// 			trans: 'http://47.244.129.144/web/trans/nature.php',
// 			orititle: 'Nature',
// 			tratitle: '自然'
// 		}
// 		case 'pubmed': return {
// 			origin: !window.$isH5?'https://www.ncbi.nlm.nih.gov/pubmed':'https://www.ncbi.nlm.nih.gov/m/pubmed',
// 			trans: !window.$isH5?'http://47.244.129.144/web/trans/pubmed.html':'http://47.244.129.144/web/trans/mpubmed.html',
// 			orititle: 'PubMed',
// 			tratitle: 'PubMed'
// 		}
// 		case 'asm': return {
// 			origin: 'https://journals.asm.org',
// 			trans: 'http://47.244.129.144/web/trans/journal.asm.html',
// 			orititle: 'Asm',
// 			tratitle: 'Asm'
// 		}
// 		case 'jstor': return {
// 			origin: 'https://www.jstor.org',
// 			trans: 'http://47.244.129.144/web/trans/jstor.html',
// 			orititle: 'Asm',
// 			tratitle: 'Asm'
// 		}
// 		default:
// 	}
// }

// 全局积分不足弹出框
export function lackOfIntegral(){
	if(document.querySelector('#lackOfIntegral')){
		return false;
	}
	let href = window.$isH5 ? config.H5_HOST+'/recharge' : config.routeHost+'/pay.html'
	let modal = document.createElement('div');
	modal.id = 'lackOfIntegral';
	modal.innerHTML = `<div class="modal-mask"></div>
		<div class="modal-wrap ">
			<div class="modal" style="width:${window.$isH5?'80vw':'440px'}">
				<div class="modal-content" style="padding: 10px 40px 0px;">
					<button class="modal-close" aria-label="close" onClick="document.querySelector('#lackOfIntegral').remove()"></button>
					<h6 class="modal-header" style="font-size:18px;text-align:center">提示</h6>
					<div class="modal-body">
						<p style="text-align:center">积分不足</p>
						<button class="modal-btn" style="margin:0 auto;display:block;margin-top:20px;width:${window.$isH5?'100%':'260px'}"><a href=${href} target="_blank">前往充值</a></button>
					</div>
				</div>
			</div>
		</div>`
	document.body.appendChild(modal);
}

// 全局消息提示
export function tooltip(data){
	message.destroy();
	let type = data.type;
	let msg = data.msg;
	let time = data.time?data.time:2;
	type ? message[type](msg,time) : message.info(msg,time);
}

// 设置为app环境
export function isApp(){
	window.$isapp = true
}

// 深拷贝
export function deepClone(target) {
    let result;
    if (typeof target === 'object') {
        if (Array.isArray(target)) {
            result = []; // 
            for (let i in target) {
                result.push(deepClone(target[i]))
            }
        } else if (target === null) {
            result = null;  
        } else if (target.constructor === RegExp) {
            result = target;
        } else {
            result = {};
            for (let i in target) {
                result[i] = deepClone(target[i]);
            }
        }
    } else {
        result = target;
    }
    return result;
}

// 对参数按a-z排序
export function sortByKey(arys) {
    //先用Object内置类的keys方法获取要排序对象的属性名数组，再利用Array的sort方法进行排序
    var newkey = Object.keys(arys).sort();
    var newObj = ''; //创建一个新的对象，用于存放排好序的键值对
    for (var i = 0; i < newkey.length; i++) {
        //遍历newkey数组
        if (arys[newkey[i]] === '') {
            continue;
        } else {
            newObj += [newkey[i]] + '=' + arys[newkey[i]] + '&';
        }
    }
    return newObj.substring(0, newObj.length - 1);
}

// 参数加签
export function encrypt(params){
    params.deviceType = getDeviceType()
    params.deviceId = (params.deviceType!=='web'&&params.deviceType!=='wap') ? Cookies.get('appDeviceId')||'' : getGuid();
    params.appid = '4WE3M4A2E8';
    params.timestamps = new Date().getTime();
    var data = deepClone(params);
    data.privatekey='WEAM65AS16EW2FA6VZCHHR6ZF4HW1R2K'
    params.token = md5(sortByKey(data)).toUpperCase();
    return params
}

// 获取DeviceType
export function getDeviceType(){
    if(window.$isapp){
        if(checkEnv('ios')) return 'iOS';
        if(checkEnv('android')) return 'android';
    }else{
        if(window.isMini){
            return "wxApplets";
        }else if(window.$isH5){
            return "wap";
        }else{
            return 'web';
        }
    }
}

// 判断是否为正式环境
export function isProduction() {
    return window.location.hostname.indexOf('fanyigou')!==-1 && window.location.hostname.indexOf('dev')===-1;
}

// 判断是否为网站翻译域名模式
export function isTransWeb(){
    let arr = window.location.hostname.split('.');
    if(arr.length===3){
        return !['yidong','zhishi','webtrans'].includes(arr[0].toLocaleLowerCase())&&isProduction()
    }else if(arr.length===4){
        return ['yidong','webtrans'].includes(arr[1].toLocaleLowerCase())?false:true
    }else if(arr.length===1){
        return false
    }
}

// 深拷贝
export function deepMapKeys(obj,fn){
    return Array.isArray(obj)
    ? obj.map(val => deepMapKeys(val, fn))
    : typeof obj === 'object'
    ? Object.keys(obj).reduce((acc, current) => {
        const key = fn ? (current) : current;
        const val = obj[current];
        acc[key] =
          val !== null && typeof val === 'object' ? deepMapKeys(val, fn) : val;
        return acc;
      }, {})
    : obj
}

// 格式化时间戳
export function formatTime(date, sym1, sym2) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    return [year, month, day].map(formatNumber).join(sym1 ? sym1 : '-')+' '+[hour, minute, second].map(formatNumber).join(sym2 ? sym2 : ':')
}

function formatNumber(n){
    n = n.toString()
    return n[1] ? n : '0' + n
}

// 生成随机字符串
export function randomWord(randomFlag, min, max){
    var str = "",
        range = min,
        arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
 
    // 随机产生
    if(randomFlag){
        range = Math.round(Math.random() * (max-min)) + min;
    }
    for(var i=0; i<range; i++){
        var pos = Math.round(Math.random() * (arr.length-1));
        str += arr[pos];
    }
    return str;
}

// 获取域名后缀
export function getHostSuffix(){
    let arr = window.location.host.split('.')
    return window.location.host.indexOf('fanyigou')===-1 ? 'net' : arr[arr.length-1]
}

// 兼容ie浏览器的scroll
export function compatibleScroll(el,options){
    if(checkEnv('ie')){
        if(options.top!==undefined) el.scrollTop = options.top 
        if(options.left!==undefined) el.scrollLeft = options.left 
    }else{
        el.scroll(options)
    }
}

// 获取IE版本
export function IEVersion() {
    // 取得浏览器的userAgent字符串
    var userAgent = window.navigator.userAgent;
    // 判断是否为小于IE11的浏览器
    var isLessIE11 = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1;
    // 判断是否为IE的Edge浏览器
    var isEdge = userAgent.indexOf('Edge') > -1 && !isLessIE11;
    // 判断是否为IE11浏览器
    var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
    if (isLessIE11) {
        var IEReg = new RegExp('MSIE (\\d+\\.\\d+);');
        // 正则表达式匹配浏览器的userAgent字符串中MSIE后的数字部分，，这一步不可省略！！！
        IEReg.test(userAgent);
        // 取正则表达式中第一个小括号里匹配到的值
        var IEVersionNum = parseFloat(RegExp['$1']);
        if (IEVersionNum === 7) {
            // IE7
            return 7
        } else if (IEVersionNum === 8) {
            // IE8
            return 8
        } else if (IEVersionNum === 9) {
            // IE9
            return 9
        } else if (IEVersionNum === 10) {
            // IE10
            return 10
        } else {
            // IE版本<7
            return 6
        }
    } else if (isEdge) {
        // edge
        return 'edge'
    } else if (isIE11) {
        // IE11
        return 11
    } else {
        // 不是ie浏览器
        return -1
    }
}