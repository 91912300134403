import React, { PureComponent } from 'react'
import fetch from './http'
import QS from 'qs'
import { message } from 'antd';
import { Router, Switch, Route } from 'react-router-dom'
import { createBrowserHistory } from "history"
import Cookies from 'js-cookie';
import MyContext from './context'
import { isTransWeb, IEVersion } from './util/util'
import './style/lib/404.less'
const history = createBrowserHistory()
const isH5 = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent);
const asyncComponent = (importComponent) => {
	return class AsyncComponent extends PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				component: null
			};
		}
		async componentDidMount() {
			const { default: component } = await importComponent();
			this.setState({
				component: component
			});
		}
		render() {
			const C = this.state.component;
			return C ? <C {...this.props} /> : null;
		}
	}
}

const Async404 = asyncComponent(() => import("./views/404"))
const AsyncIndexPage = asyncComponent(() => import("./views/indexPage"))
const AsyncSearchPage = asyncComponent(() => import("./views/searchPage"))
const AsyncContentPage = asyncComponent(() => import("./views/contentPage"))
const AsyncReaderPagePC = asyncComponent(() => import("./views/readerPage"))
const AsyncReaderPageH5 = asyncComponent(() => import("./views/readerPageH5"))
const AsyncTransWebPage = asyncComponent(() => import("./views/transWebPage"))
const AsyncFeedBackPageH5 = asyncComponent(() => import("./views/feedBackPageH5"))
const AsyncReaderPage = isH5 ? AsyncReaderPageH5 : AsyncReaderPagePC;

//ie浏览器提示
const KillIe = () => {  
    return (
        <div className="kill-wrap" id="kill-ie">
            <div className="box1"></div>
            <div className="box2">
                <div className="box3">您当前浏览器版本过低，存在安全风险，建议您升级浏览器</div>
                <div className="box4">
                    <a title="下载chrome" className="a2" href="https://www.google.cn/intl/zh-CN/chrome/browser/desktop/" target="_blank" rel='noopener noreferrer' data-rel="chrome"> </a>
                    <a title="下载firefox" className="a2" href="https://www.mozilla.org/zh-CN/firefox/new/" target="_blank" rel='noopener noreferrer' data-rel="firefox"> </a>
                </div>
            </div>
        </div>
    )
}

export default class t extends PureComponent {
	constructor(props) {
		super(props);
		let search = history.location.search;
        let query = search?QS.parse(search.slice(1)):{};
		if(query.env==='mini'){
            window.isMini = true;
            document.body.classList.add('miniprogram');
            Cookies.set('appDeviceId',query.devideid,{ domain:'.fanyigou.net', expires: 7, path: '/' });
            if(query.qxToken){
				Cookies.set('Qx-Token',query.qxToken,{ domain:'.fanyigou.net', expires: 7, path: '/' });
			}else{
				Cookies.set('Qx-Token','',{ domain:'.fanyigou.net', expires: 7, path: '/' });
            }
            if(query.qxNormalToken){
				Cookies.set('Qx-Normal-Token',query.qxNormalToken,{ domain:'.fanyigou.net', expires: 7, path: '/' });
			}else{
				Cookies.set('Qx-Normal-Token','',{ domain:'.fanyigou.net', expires: 7, path: '/' });
			}
        }
		this.state = {
            isMini: query.env==='mini'&&window.$isH5,
			env: query.env||'',//页面的环境
			userStatus: false,
			userInfo: {},
            isTransWeb: isTransWeb(),
            recommendWebSiteList:[]
		}
		this.loginByToken();
		this.loginOut = this.loginOut.bind(this);
    };
	componentDidMount() {
        const version = IEVersion()
        if( version>0 && version<10 ){
            this.setState({isReady:true})
            return this.render = function(){return (<KillIe />)}
        }
        this.getRecommendWebSiteList()
        history.listen(()=>{
            this.getRecommendWebSiteList()
        })
    }; 
	// 获取用户登录信息
    loginByToken(){
        if(Cookies.get('Qx-Normal-Token')){
            fetch.getBaseUserInfo( res => {
                if (res.code === 0) {
                    this.setState({
                        userInfo:{
                            ...res.data,
                            head_img:res.data.headImg
                        },
                        userStatus:true
                    })
                }else{
                    this.setState({
                        userInfo:{},
                        userStatus:false
                    })
                }
            })
        }else{
            /*fetch.getBaseUserInfo( res => {
                if (res.code === 0) {
                    this.setState({
                        userInfo:res.data,
                        userStatus:true
                    })
                }else{
                    this.setState({
                        userInfo:{},
                        userStatus:false
                    })
                }
            })*/
        }
	}
	// 此处为退出登录请求
    loginOut(){
        fetch.webLoginOut(res=>{
            if(res.code===0){
                history.push('/')
                this.setState({
                    userInfo:{},
                    userStatus:false
                })
                Cookies.remove('Qx-Normal-Token');
            }else{
                message.destroy();
                message.info(res.message)
            }
        });
    }
    // 获取推荐网站
    getRecommendWebSiteList(){
        let pathname = history.location.pathname
        if((pathname==='/'||pathname==='/search')&&!this.state.recommendWebSiteList.length)
        fetch.getRecommendWebSiteList((res)=>{
            if(res.code===0){
                let data = res.data
                let recommendWebSiteList = data.recommendWebSiteList
                this.setState({
                    recommendWebSiteList
                })
            }
        })
    }
	render() {
        if(!this.state.isTransWeb){
			return (
                <MyContext.Provider value={{...this.state,history,loginOut:this.loginOut}}>
                    <Router history={history}>
                        <Switch>
                            <Route exact path='/' component={AsyncIndexPage} />
                            <Route path='/search' component={AsyncSearchPage} />
                            <Route path='/website-and-content' component={AsyncContentPage} />
                            <Route path='/read' component={AsyncReaderPage} />
                            <Route path='/transweb/:id' component={AsyncTransWebPage} />
                            <Route path='/feedback' component={AsyncFeedBackPageH5} />
                            <Route path='*' component={Async404} />
                        </Switch>
                    </Router>
                </MyContext.Provider>
			)
		}else{
			return (
                <MyContext.Provider value={{...this.state,history,loginOut:this.loginOut}}>
                    <Router history={history}>
                        <Switch>
                            <Route exact path='/' component={AsyncTransWebPage} />
                            <Route path='/website-and-content' component={AsyncContentPage} />
                            <Route path='/read' component={AsyncReaderPage} />
                            <Route path='/feedback' component={AsyncFeedBackPageH5} />
                            <Route path='*' component={Async404} />
                        </Switch>
                    </Router>
                </MyContext.Provider>
			)
		}
	}
}