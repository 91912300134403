if (!window.location.origin) 
window.location.origin = window.location.protocol + "//" + window.location.host

const isDevelop = process.env.NODE_ENV === 'development';
const isProduction = window.location.origin.indexOf('fanyigou')!==-1 && window.location.origin.indexOf('dev')===-1;
const host = isProduction ? window.location.origin.replace(/:\/\/[a-zA-Z-]+./,'://www.') : (window.location.protocol+'//develop.fanyigou.net');
const H5_host = isProduction ? window.location.origin.replace(/:\/\/[a-zA-Z-]+./,'://wap.') : 'http://dev.wap.fanyigou.net'
const zhishi_host = isDevelop ? '/api' : (host +'/webTrans');
const web_ori_host = isProduction ? window.location.origin.replace(/:\/\/[a-zA-Z-]+./,'://www.')+'/trans' : 'http://develop.fanyigou.net/trans';
const web_host = isProduction ? window.location.origin.replace(/:\/\/[a-zA-Z-]+./,'://www.') : 'http://develop.fanyigou.net';
const fanyigou_host = isDevelop ? '/apc' : host;
const fanyigou_host_h5 = isDevelop ? '/apc' : (H5_host+'/wap');


export default {
    isProduction,
    HOST : web_host,
    H5_HOST : H5_host,
    routeHost : web_ori_host,
    zhishi_host : zhishi_host,
    fanyigou_host : fanyigou_host,
    fanyigou_host_h5 : fanyigou_host_h5
};