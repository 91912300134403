import request from './request'
import config from '../config'


const zhishi_host = config.zhishi_host;
const fanyigou_host = config.fanyigou_host;


const api = {
    // 3.0版本
    getRecommendWebSiteList         : zhishi_host+'/webSite/getRecommendWebSiteList',// 获取推荐的网站
    getRouteWebSite                 : zhishi_host+'/webSite/getRouteWebSite',// 获取已有的网站路由
    htmlTrans                       : zhishi_host+'/trans/htmlTrans',// 网页翻译
    search                          : zhishi_host+'/trans/search',// 搜索功能
    updateStayTime                  : zhishi_host+'/trans/updateStayTime',// 更新页面停留的时间
    getTransWebInfo                 : zhishi_host+'/file/getDocInfo',//获取pdf信息
    transDoc                        : zhishi_host+'/file/transDoc',// 文件翻译
    decideWebSiteIsCollected        : zhishi_host+'/collect/decideWebSiteIsCollected',// 判断网站是否收藏
    collectWebSite                  : zhishi_host+'/collect/collectWebSite',// 收藏网站
    cancelCollectWebSite            : zhishi_host+'/collect/cancelCollectWebSite',// 取消收藏网站
    getCollectWebSiteList           : zhishi_host+'/collect/getCollectWebSiteList',// 获取收藏的网站信息
    getWebSiteInformationByUrl      : zhishi_host+'/collect/getWebSiteInformationByUrl',// 判断网站资讯是否收藏
    collectWebSiteInformation       : zhishi_host+'/collect/collectWebSiteInformation',// 收藏网站资讯
    cancelCollectWebSiteInformation : zhishi_host+'/collect/cancelCollectWebSiteInformation',// 取消收藏网站资讯
    getCollectWebSiteInformationList: zhishi_host+'/collect/getCollectWebSiteInformationList',// 获取收藏的网站资讯列表
    decideCollectDoc                : zhishi_host+'/collect/decideCollectDoc',// 判断文档是否收藏
    collectDoc                      : zhishi_host+'/collect/collectDoc',// 收藏文档
    cancelCollectDoc                : zhishi_host+'/collect/cancelCollectDoc',// 取消收藏文档
    getCollectDocList               : zhishi_host+'/collect/getCollectDocList',// 获取收藏文档的信息
   
    
    //小程序
    collectSecondWeb                : zhishi_host+'/wxApplets/collectSecondWeb',//收藏文章
    cancelCollectSecondWeb          : zhishi_host+'/wxApplets/cancelCollectSecondWeb',//取消收藏文章
    decideCollectSecondWeb          : zhishi_host+'/wxApplets/decideCollectSecondWeb',//网页是否文章
    sendEmail                       : zhishi_host+'/wxApplets/sendEmail',//译文发送邮件
    sendEmailForOri                 : zhishi_host+'/wxApplets/sendEmailForOri',//原文发送邮件
    getUserEmail                    : zhishi_host+'/wxApplets/getUserEmail',//获取最近有效的邮箱

    // 翻译狗
    getLanguage                     : fanyigou_host+'/sdoc/web/getLanguage',//获取可用语言列表
    getTopLan                       : fanyigou_host+'/sdoc/web/getTopLan',//获取常用的原语言和目标语言
    tiprecord                       : fanyigou_host+'/payment/monitor/tiprecord',//积分不足弹框监控
    officalWebsite                  : fanyigou_host+'/qxscore/checkScore/officalWebsite',//检查积分中文版
    getImage                        : fanyigou_host+'/sdoc/image/getImage',//获取文档图片
    checkWord                       : fanyigou_host+'/sdoc/web/checkDocFormat',//检查译文word是否存在
    downDoc                         : fanyigou_host+'/sdoc/web/downDoc',//下载文档（译文pdf,word)
    queryTranslateDoc               : fanyigou_host+'/sdoc/web/queryDocProgress',//获取翻译进度
    getBaseUserInfo                 : fanyigou_host+'/users/userInfo/web/getBaseUserInfo',// 新获取登录状态
    oldInsertFeedBack               : fanyigou_host+'/feedBack/insertFeedBack.htm',// 旧反馈
    newInsertFeedBack               : fanyigou_host+'/notice/feedBack/insertFeedBack',// 新反馈
    weekly                          : fanyigou_host+'/notice/product/webTrans/weekly',// 
    webLoginOut                     : fanyigou_host+'/users/loginOut/web/loginOut',// 退出

    // chrome插件
    messageDose                     : zhishi_host+'/newtrans/messageDose',// 埋点
    
}


export default {                   
    checkDonwStat                   : (...arg) => request('POST', api.checkDonwStat, ...arg),
    getWeixinConfig                 : (...arg) => request('POST', ...arg),  //获取微信配置信息 

    collectSecondWeb                : (...arg) => request('POST', api.collectSecondWeb, ...arg),
    cancelCollectSecondWeb          : (...arg) => request('POST', api.cancelCollectSecondWeb, ...arg),
    decideCollectSecondWeb          : (...arg) => request('POST', api.decideCollectSecondWeb, ...arg),
    sendEmail                       : (...arg) => request('POST', api.sendEmail, ...arg),
    sendEmailForOri                 : (...arg) => request('POST', api.sendEmailForOri, ...arg),
    getUserEmail                    : (...arg) => request('POST', api.getUserEmail, ...arg),

    // 3.0版本
    getRecommendWebSiteList         : (...arg) => request('POST', api.getRecommendWebSiteList, ...arg),
    getRouteWebSite                 : (...arg) => request('POST', api.getRouteWebSite, ...arg),
    htmlTrans                       : (...arg) => request('POST', api.htmlTrans, ...arg),
    search                          : (...arg) => request('POST', api.search, ...arg),
    updateStayTime                  : (...arg) => request('POST', api.updateStayTime, ...arg),
    getTransWebInfo                 : (...arg) => request('POST', api.getTransWebInfo, ...arg), 
    translateWebPdf                 : (...arg) => request('POST', api.transDoc, ...arg),
    decideWebSiteIsCollected        : (...arg) => request('POST', api.decideWebSiteIsCollected, ...arg),    
    collectWebSite                  : (...arg) => request('POST', api.collectWebSite, ...arg),
    cancelCollectWebSite            : (...arg) => request('POST', api.cancelCollectWebSite, ...arg),
    getCollectWebSiteList           : (...arg) => request('POST', api.getCollectWebSiteList, ...arg),
    getWebSiteInformationByUrl      : (...arg) => request('POST', api.getWebSiteInformationByUrl, ...arg),
    collectWebSiteInformation       : (...arg) => request('POST', api.collectWebSiteInformation, ...arg),
    cancelCollectWebSiteInformation : (...arg) => request('POST', api.cancelCollectWebSiteInformation, ...arg),
    getCollectWebSiteInformationList: (...arg) => request('POST', api.getCollectWebSiteInformationList, ...arg),
    decideCollectDoc                : (...arg) => request('POST', api.decideCollectDoc, ...arg),
    collectDoc                      : (...arg) => request('POST', api.collectDoc, ...arg),
    cancelCollectDoc                : (...arg) => request('POST', api.cancelCollectDoc, ...arg),
    getCollectDocList               : (...arg) => request('POST', api.getCollectDocList, ...arg),

    // 翻译狗
    getLanguage                     : (...arg) => request('POST', api.getLanguage, ...arg),
    getTopLan                       : (...arg) => request('POST', api.getTopLan, ...arg),
    tiprecord                       : (...arg) => request('POST', api.tiprecord, ...arg),
    officalWebsite                  : (...arg) => request('POST', api.officalWebsite, ...arg),
    getImage                        : (...arg) => request('POST', api.getImage, ...arg),
    checkWord                       : (...arg) => request('POST', api.checkWord, ...arg),
    downDoc                         : (...arg) => request('POST', api.downDoc, ...arg),
    transWebPdfHtmlQuery            : (...arg) => request('POST', api.queryTranslateDoc, ...arg),
    getBaseUserInfo                 : (...arg) => request('POST', api.getBaseUserInfo, ...arg),
    oldInsertFeedBack               : (...arg) => request('POST', api.oldInsertFeedBack, ...arg),
    newInsertFeedBack               : (...arg) => request('POST', api.newInsertFeedBack, ...arg),
    weekly                          : (...arg) => request('POST', api.weekly, ...arg),
    webLoginOut                     : (...arg) => request('POST', api.webLoginOut, ...arg),
    messageDose                     : (...arg) => request('POST', api.messageDose, ...arg),
}