import axios from 'axios';
import QS from 'qs';
import Cookies from 'js-cookie';
import { message } from 'antd'
import { getGuid,sortByKey,deepClone,getDeviceType } from '../util/util';
import md5 from '../util/md5.js'
import Fingerprint2 from 'fingerprintjs2'
import { toLogin, lackOfIntegral } from '../util/util'

let deviceId = getGuid();

// 请求超时时间
axios.defaults.timeout = 20000;
axios.defaults.withCredentials=true


// 请求拦截器
axios.interceptors.request.use(    
    config => {
        return config;    
    },    
    error => {        
        return Promise.error(error);    
    })

// 响应拦截器
axios.interceptors.response.use(    
    res => {
        !window.$isH5 && window.NProgress.done();
        if (res.status === 200) {  
            let code = res.data.code;
            let url = res.config.url;
            if(code===-4000009||code===-4000000){
                if(url.indexOf('/users/userInfo/web/getBaseUserInfo')!==-1||
                    url.indexOf('wxApplets')!==-1
                ) return
                message.destroy();
                message.info(res.data.msg,1);
                setTimeout(function(){
                    toLogin();
                },1000)
            }else if(code===-1900002){
                lackOfIntegral();
            }else{
                return Promise.resolve(res); 
            }      
        } else {            
            return Promise.reject(res);        
        }    
    },
    error => {    
        !window.$isH5 && window.NProgress.done();
        if (error.response.status) {            
            switch (error.response.status) {                              
                case 401:                    
                    break;              
                case 403:       
                    message.destroy();
                    message.error('登录过期，请重新登录')                                                           
                    setTimeout(() => {                        
                        toLogin();                   
                    }, 1000);                    
                    break;              
                case 404: 
                    message.destroy();
                    message.error('网络请求不存在')                                  
                    break;     
                case 504: 
                    message.destroy();
                    message.error('网页请求超时')                                  
                    break;                          
                default:   
                    message.destroy();
                    message.error('服务器在开小差')             
            }            
            return Promise.reject(error.response);        
        }       
    }
);


function encrypt(params){
    params.deviceType = getDeviceType()
    params.deviceId = (params.deviceType!=='web'&&params.deviceType!=='wap') ? Cookies.get('appDeviceId')||'' : deviceId
    params.appid = '4WE3M4A2E8';
    params.timestamps = new Date().getTime();
    var data = deepClone(params);
    data.privatekey='WEAM65AS16EW2FA6VZCHHR6ZF4HW1R2K'
    params.token = md5(sortByKey(data)).toUpperCase();
    return params
}

function encryptForm(params) {
    var formdata = new FormData()
    params.deviceType = getDeviceType()
    params.deviceId = (params.deviceType!=='web'&&params.deviceType!=='wap') ? Cookies.get('appDeviceId')||'' : deviceId
    params.appid = '4WE3M4A2E8'
    params.timestamps = new Date().getTime()
    var data = deepClone(params);
    delete data.file
    data.privatekey='WEAM65AS16EW2FA6VZCHHR6ZF4HW1R2K'
    formdata.append('token',md5(sortByKey(data)).toUpperCase())
    for (var key in params) {
        formdata.append(key,params[key])
    }
    return formdata
}

export default function request(...arg){
    let len = arg.length;
    let method = arg[0];
    let url = arg[1];
    let params={},cb,config,fail;
    if(len===3){
        cb = arg[2];
    }else if(len===4){
        params = arg[2];
        cb = arg[3];
    }else if(len===5){
        params = arg[2];
        config = arg[3];
        cb = arg[4];
    }else{
        params = arg[2];
        config = arg[3];
        cb = arg[4];
        fail = arg[5];
    }

    let showNProgress = params ? (params.noNProgress?false:true) : true;
    if(!window.$isH5&&showNProgress) window.NProgress.start();
    if(params && params.noNProgress!==undefined) delete params.noNProgress;

    let options = {
        ...config,
        method,
        url,
    };
    if(window.$isapp||window.isMini){
        return doReq();
    }else{
        if(!deviceId){
            Fingerprint2.get(function (components) {
                var values = components.map(function (component,index) { 
                    return index===4?8:component.value
                });
                var murmur = Fingerprint2.x64hash128(values.join(''), 31);
                let domains = window.location.host.match(/.fanyigou.[\w]+$/)
                let domain = domains?domains[0]:null;
                deviceId = murmur;
                Cookies.set('DeviceId',murmur,{ domain:domain, expires: 7, path: '/' });
                return doReq();
            })  
        }else{
            return doReq();
        }
    }
    
    function doReq(){
        if(method==='GET'){
            if(!params) params = {}
            encrypt(params)
            options.params = params
        }else{
            if(params && params.requestType==='form'){
                delete params.requestType
                options.data = encryptForm(params)
            }else{
                if(!params) params = {}
                encrypt(params)
                options.data = QS.stringify(params)
            } 
        }
        return axios(options).then(res => {      
            cb && cb(res.data);          
        })        
        .catch(err => {      
            fail && fail(err);        
        })
    }
}