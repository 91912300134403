import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './style/index.less'

process.env.NODE_ENV === 'development' &&  require('./setupProxy');

window.$util = require('./util/util');
window.$routeTarget = require('./json/routeTarget');
// app webview环境
window.navigator.userAgent.indexOf(';app') !== -1 && (window.$isapp = true)


ReactDOM.render(
    <App />,
    document.getElementById('root')
);